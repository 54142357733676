import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import DEFAULT from './DEFAULT.mjs'
import INNOSHIP_BOX from './INNOSHIP_BOX.mjs'
import INNOSHIP_POK from './INNOSHIP_POK.mjs'
import DRMAX_BOX from './DRMAX_BOX.mjs'
import PHARMACY from './PHARMACY.mjs'
import config from '../index.mjs'
import map from './map.mjs'

export default defineAppConfig({
  mapConfig: {
    ...config,
    ...map,
  },
  placePrototypes: {
    DEFAULT,
    INNOSHIP_BOX,
    INNOSHIP_POK,
    DRMAX_BOX,
    PHARMACY,
  },
  timezone: 'Europe/Prague',
})
